import Cookie, { CookieAttributes } from 'js-cookie'
import compact from 'lodash/compact'
import isFunction from 'lodash/isFunction'

import type { Storage, StorageKey, StorageOptions } from '../Types'

export type CookieSameSiteValue = 'Lax' | 'Strict' | 'None'

export interface CookieStorageOptions extends StorageOptions {
  /**
   * If the cookie will be saved as secure cookie (default: false).
   * The value can also be a function that which needs to return a valid value
   * or undefined.
   */
  cookieSecure?: boolean | ((key?: StorageKey) => boolean | undefined);

  /**
   * Settings the same site policy for the cookie (default: undefined). Needs
   * to be type of {@link CookieSameSiteValue} or undefined.
   * The value can also be a function that which needs to return a valid value
   * or undefined.
   */
  sameSite?: CookieSameSiteValue | (
    (key?: StorageKey) => CookieSameSiteValue | undefined
  );

  /**
   * Cookie expiry setting in days (default: undefined).
   * The value can also be a function that which needs to return a valid value
   * or undefined.
   */
  expires?: number | ((key?: StorageKey) => number | undefined);
}

/**
 * Cookie storage interface for OAuth data.
 */
class CookieStorage implements Storage<CookieStorageOptions> {
  options: CookieStorageOptions = {}

  constructor(options: CookieStorageOptions = {}) {
    this.options = options

    if (!this.options.cookieSecure) this.options.cookieSecure = false
    if (!this.options.sameSite) this.options.sameSite = undefined
    if (!this.options.expires) this.options.expires = undefined
    if (!this.options.prefix) this.options.prefix = ''
  }

  /**
   * Get the cookie value
   */
  async get(key: StorageKey): Promise<string | undefined> {
    return Cookie.get(this.getCookieName(key)) || undefined
  }

  /**
   * Sets the value to a cookie
   */
  async set(key: StorageKey, value: string | undefined): Promise<boolean> {
    const options = this.getCookieSetOptions(key)
    const sameSite = this.getCookieSameSite(key)

    if (sameSite) options.sameSite = sameSite
    options.expires = this.getCookieExpires(key)

    Cookie.set(this.getCookieName(key), value || '', options)

    return true
  }

  /**
   * Returns the secure value for cookies to set. The value is controlled by the env
   * variable Config.cookieSecure. If nothing is passed in, it will return
   * false by default.
   */
  getCookieSecure(key?: StorageKey | undefined): boolean {
    if (isFunction(this.options.cookieSecure)) {
      return this.options.cookieSecure(key) === true
    }

    return this.options.cookieSecure === true
  }

  /**
   * Returns the cookie sameSite policy. If nothing is passed in, it will
   * return 'Strict' by default.
   */
  getCookieSameSite(key?: StorageKey | undefined): CookieSameSiteValue {
    if (isFunction(this.options.sameSite)) {
      return this.options.sameSite(key) || 'Strict'
    }

    return this.options.sameSite || 'Strict'
  }

  /**
   * Returns the cookie expires option value for different cookie types.
   * There are the types 'token' and 'token_data' to pass as parameter. Every other
   * value will return undefined.
   *
   * @return Token expires value in days / null.
   */
  getCookieExpires(key?: StorageKey | undefined): number | undefined {
    if (isFunction(this.options.expires)) return this.options.expires(key)

    return this.options.expires
  }

  /**
   * Returns the options hash for the js-cookie library`s .set method.
   * @return {Object} The options object.
   */
  getCookieSetOptions(key?: StorageKey | undefined): CookieAttributes {
    const options = { secure: this.getCookieSecure(key) }

    return options
  }

  /**
   * Returns the cookie name prefix from VayaPinOAuthKit config.
   */
  getCookieNamePrefix(): string {
    if (isFunction(this.options.prefix)) return this.options.prefix() || ''

    return this.options.prefix || ''
  }

  /**
   * Returns the cookie name based on the passed type.
   * @param key Cookie key
   * @return The cookie name or null
   */
  getCookieName(key: StorageKey): string {
    return compact([
      this.getCookieNamePrefix(),
      key
    ]).join('-')
  }
}

export default CookieStorage
