import compact from 'lodash/compact'
import isFunction from 'lodash/isFunction'

import type { Storage, StorageKey, StorageOptions } from '../Types'

/**
 * LocalStorage interface for OAuth data.
 */
class LocalStorage implements Storage<StorageOptions> {
  options: StorageOptions = {}

  constructor(options: StorageOptions = {}) {
    this.options = options
  }

  /**
   * Get the cookie value
   */
  async get(key: StorageKey): Promise<string | undefined> {
    return window.localStorage.getItem(this.getKey(key)) || undefined
  }

  /**
   * Sets the value to the storage
   */
  async set(key: StorageKey, value: string | undefined): Promise<boolean> {
    window.localStorage.setItem(this.getKey(key), value || '')

    return true
  }

  /**
   * Returns the localStorage name prefix from VayaPinOAuthKit config.
   */
  getLocalStoragePrefix() {
    if (isFunction(this.options.prefix)) return this.options.prefix() || ''

    return this.options.prefix || ''
  }

  /**
   * Returns full key based on Config and passed key
   * @param key The key
   * @return Full key with prefix
   */
  getKey(key: StorageKey): string {
    return compact([
      this.getLocalStoragePrefix(),
      key
    ]).join('-')
  }
}

export default LocalStorage
